<!-- 本场名单 -->
<template>
    <div class="NameList">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">本场名单</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="certificate_no" label="准考证号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="name" label="学员姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="english_name" label="拼音名" align="center" width="150">
            </el-table-column>
            <el-table-column prop="gender" label="性别" align="center" width="80">
                <template slot-scope="scope">
                    <span v-if="scope.row.gender==1">男</span>
                    <span v-else-if="scope.row.gender==2">女</span>
                </template>
            </el-table-column>
            <el-table-column prop="birthdate" label="出生日期" align="center" width="150">
            </el-table-column>
            <el-table-column prop="nation2" label="国籍" align="center" width="100">
            </el-table-column>
            <el-table-column prop="nation" label="民族" align="center" width="100">
            </el-table-column>
            <el-table-column prop="course" label="学科" align="center" width="200">
            </el-table-column>
            <el-table-column prop="exam_level" label="等级" align="center" width="100">
            </el-table-column>
            <el-table-column prop="remark" label="说明" align="center" width="400">
            </el-table-column>
            <el-table-column prop="examarea_id" label="安排单号" align="center" width="240">
            </el-table-column>
        </el-table> 
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tableData:[],//表格数据
                currentPage: 1,
                currentLimit: 20,//条数
                total:0,
            }
        },
        created() {
            // console.log(this.$route.query.id)
            this.getList()
        },
        methods:{
            getList(){
              this.$request({
                  url:'/api/Admission/sameScene',
                  method:'POST',
                  data:{
                      id:this.$route.query.id
                  }
              }).then(res=>{
                  console.log(res)
                  if(res.code==1){
                      this.tableData=res.data.list
                      this.total=res.data.count
                  }else{
                      this.$message({
                        message: res.msg,
                        type: 'error'
                      })
                  }
              })  
            },
            goBack() { //返回
                // this.$router.push({
                //     path: "admissionTicket"
                // })
                this.$router.go(-1)
            }, 
            handleSizeChange(val) {
                this.currentLimit=val//条数
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
        }
    }
</script>

<style scoped="scoped">
    .NameList{
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
</style>
